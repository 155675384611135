const imageMixin = {
  methods: {
    uploadFile(file, fileNameDef = false) {
      return new Promise((resolve) => {
        if (file) {
          this.$api.post('getSignedUrl', { filename: fileNameDef || file.name, filetype: file.type }).then((res) => {
            this.fileUploadPath = res.data.link;
            const fileEndpoint = res.data.s3Endpoint;
            this.$api.raw('PUT', this.fileUploadPath, file).then(() => {
              this.$emit(
                'input',
                fileEndpoint,
              );
              resolve(fileEndpoint);
            });
          });
        } else {
          resolve(false);
        }
      });
    },
    upload() {
      return this.uploadFile(this.file);
    },
    updatePreview(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0];
      let blob = '';
      const URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        blob = URL.createObjectURL(this.file);
      }
      this.filePreview = blob;
    },
  },
  data() {
    return {
      file: false,
      filePreview: false,
      fileUploadPath: '',
    };
  },
  props: {
    value: {
      type: String,
    },
  },
};

export default imageMixin;
