<template>
  <div class="company-attr box-shadow" :class="{darker: this.darker}">
    <div class="display-company" >
        <div class="label">
          <slot name="label">
            {{label}}
          </slot>
        </div>
      <div class="divider" />
      <!-- For Normal Input  -->
     <template v-if="!this.type">
        <div class="val" v-if="!editing ">
          {{this.value}}
        </div>
        <div class="val" v-if="editing">
          <input v-model="editVal" />
        </div>
     </template>
     <!-- For Autocomplete -->
     <template v-if="this.type === 'autocomplete'">
        <div class="val" v-if="!editing ">
          {{this.value}}
        </div>
        <div class="val" v-if="editing">
          <autocomplete v-model="editVal" :terms="autoCompleteTerms" />
        </div>
     </template>
     <!-- Number -->
     <template v-if="this.type === 'number'">
        <div class="val" v-if="!editing ">
          {{this.value}}
        </div>
        <div class="val" v-if="editing">
          <input type="number" v-model.number="editVal" />
        </div>
     </template>

      <!-- For HTML Input  -->
     <template v-else-if="this.type === 'html'">
        <div class="val" v-if="!editing " v-html="this.value">
          <!-- <div class="html-val" v-html="this.value" /> -->
        </div>
        <div class="val" v-if="editing">
          <wysiwyg class="wysiwyg radius" v-model="editVal" />
        </div>
     </template>

     <!-- For media Input  -->
     <template v-else-if="this.type === 'media' || this.type === 'proPic'">
        <div class="val media" v-if="!editing ">
          <cropper
            v-if="this.cropping"
            class="cropper"
            :src="this.value"
            ref="cropperObj"
            stencil-component="circle-stencil"
            @ready="endLoading"
            />
            <img :src="this.value" v-else/>
            <loading v-if="cropLoading" />

        </div>
     </template>
     <template v-else-if="this.type === 'custom'">
        <slot name="display" v-if="!editing "></slot>
        <slot name="edit" v-else></slot>
     </template>

      <div class="button-comp-cont">
        <div class="edit-button small radius-small editing" @click="submitChange" v-if="editing">
          Submit
        </div>
        <div class="edit-button small radius-small"
          :class="this.editing ? 'editing red' : ''"
          @click="toggleEdit"
          v-if="!cropping"
        >
          {{this.editing ? 'Cancel': 'Edit'}}
        </div>
        <template v-if="this.type === 'proPic'">
          <div class="edit-button small radius-small editing "
            v-if="cropping" @click="submitCrop"
          >
            Submit
          </div>
          <div class="edit-button small radius-small crop-btn"
            :class="this.cropping ? 'editing' : ''"
            @click="toggleCropping"
          >
            {{!this.cropping? 'Crop': 'Cancel'}}
          </div>

        </template>
      </div>

      <div class="outer" v-if="editing">
        <slot name="outer"></slot>
      </div>
    </div>

  </div>
</template>

<script>
import Autocomplete from '@/components/utilityPartials/_autocomplete.vue';
import selectMedia from '@/modules/mixins/selectMedia';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import imageMixin from '@/modules/mixins/ImageUpload';
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'CompanyAttributeSettings',
  mixins: [selectMedia, imageMixin],
  components: {
    Autocomplete,
    Cropper,
    Loading,
  },
  props: {
    value: {
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    darker: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    startEdit: {
      type: Boolean,
      default: false,
    },
    autoCompleteTerms: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      editing: this.startEdit,
      editVal: this.value,
      cropping: false,
      cropLoading: false,
    };
  },

  methods: {
    toggleEdit() {
      if (this.type === 'media' || this.type === 'proPic') {
        this.attachImage((imgFile) => {
          this.$emit('input', imgFile.data);
        });
      } else {
        this.$emit('editing');
        this.editing = !this.editing;
        this.editVal = this.value;
      }
    },
    submitChange() {
      this.$emit('input', this.editVal);
      this.$emit('submitted');
      this.toggleEdit();
    },
    submitCrop() {
      const { canvas } = this.$refs.cropperObj.getResult();
      canvas.toBlob((blob) => {
        this.cropLoading = true;
        this.cropping = false;
        const newName = `cropped_${Date.now()}${this.value}`;
        this.uploadFile(blob, newName).then((endpoint) => {
          this.cropping = false;
          this.cropLoading = false;
          this.$emit('input', endpoint);
        });
      });
    },
    toggleCropping() {
      this.cropping = !this.cropping;
      this.cropLoading = this.cropping;
    },
    endLoading() {
      this.cropLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.company-attr{
  width: calc(100% - 5vw);
  position: relative;
  padding: 1vh;
  border-radius: 1vh;
  line-height: 4vh;
  font-size: 1.8vh;
  vertical-align: middle;
  margin-bottom: 1vh;

  input{
    border: 1px solid $border_darkgray;
    border-radius: 0.5vh;
  }

  &.darker{
    // background-color:#f8f8f8;

  }
  .display-company{
    display: flex;
    flex-direction: row;
    align-content: center;
    position: relative;
    height: 100%;

    .label{
      width: 25%;
      font-weight: 800;
      margin-left: 3%;
      margin-right: 1vw;
    }
    .divider{
      top: -1vh;
      height: calc(100% + 2vh);
      left: calc(25% + 2%);
      position: absolute;
      background-color: blueviolet;
      width: 3px;
    }
    .val{
      width: 60%;
      color: #707070;
      vertical-align: center;
      word-wrap: break-word;
      input{
        padding: 1vh;
        width: 90%;
      }
       img{
        max-height: 20vh;
        width: auto
      }
    }
    .html-val{
      line-height: 2vh;
    }
    .outer{
      width: 1vw;
      height: 1vw;
      position: absolute;
      right: -2vw;
      top: -1vw;
    }
    .button-comp-cont{
      display: flex;
      width: 9%;
      position: absolute;
      right: 0;
      flex-wrap: wrap;
      margin-left: 1vw;
      flex-direction: row-reverse;
      align-self: center;

      .edit-button{
        background-color: #48ffaf;
        padding-left: .5vw;
        padding-right: .5vw;
        height: 3vh;
        line-height: 3vh;
        font-size: 1.5vh;
        cursor: pointer;
        margin-bottom: 0.5vh;
        &:last-child{
          margin-bottom: 0;
        }
        &.crop-btn{
          font-size: 1.25vh;
        }
        &.editing{
          font-size: 1vh;
          height: 2vh;
          line-height: 2vh;
          &.red{
            background-color: $red_color;
            color: #000

          }
        }
      }
    }

  }

}
</style>
